import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "../../util/t";
import { Close } from "@mui/icons-material";
import { ProgressButton } from "../forms/fields/ProgressButton";
import { LiteralStringUnion } from "type-fest/source/literal-union";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(0, 2, 1.5),
        flexWrap: "wrap",
        justifyContent: "space-between",
        position: "relative",
        "&.success": {
            backgroundColor: theme.palette.success.light
        },
        "&.info": {
            backgroundColor: theme.palette.info.light
        },
        "&.warning": {
            backgroundColor: theme.palette.warn.light
        },
        "&.error": {
            backgroundColor: theme.palette.error.light
        },
        "&.notify": {
            backgroundColor: theme.palette.secondary.main
        },
        "& span a": {
            fontWeight: 700,
            textDecoration: "underline"
        },
        "&.fit-content": {
            width: "fit-content"
        },
        "&.text-left": {
            textAlign: "left",
        },
        "&.margin-bottom": {
            marginBottom: theme.spacing(2)
        },
        "&.close-button-active": {
            padding: theme.spacing(0, 6, 1.5, 2),
        }
    },
    icon: {
        fontSize: "1.2rem",
        marginRight: theme.spacing(1)
    },
    content: {
        marginTop: theme.spacing(1.5),
        display: "flex",
        alignItems: "center",
    },
    button: {
        marginTop: theme.spacing(1.5)
    },
    closeButton: {
        width: "24px",
        position: "absolute",
        right: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        top: 0,
        bottom: 0,
    }
}));

interface NotificationProps {
    className?: string;
    type?: LiteralStringUnion<"success" | "info" | "warning" | "error" | "notify">;
    text: string;
    Icon?: React.FC<{ className: string }>
    buttonProps?: {
        label?: string;
        [key: string]: any;
    };
    closeButton?: boolean;
}

export const Notification: React.FC<NotificationProps> = ({ className, type, text, Icon, buttonProps: { label, ...rest } = {}, closeButton }) => {
    const classes = useStyles();

    return (
        <div className={classnames(classes.wrapper, type, className, { "close-button-active": !!closeButton })} role="alert">
            <div className={classes.content}>
                {Icon && <Icon className={classes.icon}/>}
                <span><T>{text}</T></span>
            </div>
            {label &&
            <ProgressButton className={classes.button} variant="outlined" {...rest}>
                <T>{label}</T>
            </ProgressButton>}
            {closeButton && <div className={classes.closeButton}><Close/></div>}
        </div>
    );
};
